define("clap-front/components/media-upload", ["exports", "clap-front/config/environment", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _environment, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PropertyPictureComponent = _exports.default = (_class = class PropertyPictureComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "notifications", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "inputValue", _descriptor5, this);
      _initializerDefineProperty(this, "projectLayer", _descriptor6, this);
      _initializerDefineProperty(this, "isModalOpen", _descriptor7, this);
      _initializerDefineProperty(this, "selectedPicIndex", _descriptor8, this);
      _initializerDefineProperty(this, "loading", _descriptor9, this);
    }
    async uploadMedia(file) {
      let userAccessToken = this.session.data.authenticated.access_token;
      if (!this.projectLayer.get('id')) {
        await this.projectLayer.save();
      }
      try {
        let response = await file.upload(`${_environment.default.APP.API_HOST}/api/video/project-layers/${this.projectLayer.get('id')}/upload-media`, {
          "headers": {
            "Authorization": `Bearer ${userAccessToken}`
          }
        });
        console.log(response);
        if (response) {
          this.projectLayer.set('file', response.body.file);
        }
      } catch (e) {
        this.notifications.error(this.intl.t("Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger."));
      }
    }
    async initProjectLayer() {
      let templatelayer = this.args.templatelayer;
      let project = this.args.project;
      let projectLayer = await this.store.query('video/project-layer', {
        "templatelayer": templatelayer.get('id'),
        "project": project.get('id')
      });
      if (projectLayer.firstObject) {
        this.projectLayer = projectLayer.firstObject;
      } else {
        this.projectLayer = this.store.createRecord('video/project-layer', {
          "templatelayer": templatelayer,
          "project": project
        });
      }
    }
    async saveSelectedPic() {
      this.loading = true;
      let userAccessToken = this.session.data.authenticated.access_token;
      if (!this.projectLayer.get('id')) {
        await this.projectLayer.save();
      }
      try {
        const response = await fetch(`${_environment.default.APP.API_HOST}/api/video/project-layers/${this.projectLayer.get('id')}/upload-media`, {
          method: "POST",
          body: JSON.stringify({
            realty_picture_index: this.selectedPicIndex
          }),
          headers: {
            "Authorization": `Bearer ${userAccessToken}`,
            "Content-Type": "application/json"
          }
        }).then(r => r.json());
        console.log(response);
        if (response) {
          this.projectLayer.set('file', response.file + "?nocache=" + new Date().toISOString());
        }
        this.isModalOpen = false;
      } catch (e) {
        this.notifications.error(this.intl.t("Une erreur est survenue lors du chargement de votre fichier. Veuillez essayer avec un fichier plus léger."));
        console.error(e);
      }
      this.loading = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "inputValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "projectLayer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isModalOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedPicIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "uploadMedia", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadMedia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initProjectLayer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initProjectLayer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSelectedPic", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveSelectedPic"), _class.prototype), _class);
});