define("clap-front/routes/application", ["exports", "@ember/service", "@ember/object", "@ember/routing/route", "clap-front/config/environment"], function (_exports, _service, _object, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ApplicationRoute = _exports.default = (_class = class ApplicationRoute extends _route.default {
    title(tokens) {
      tokens.push("clap.video");
      return tokens.join(' | ');
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "moment", _descriptor3, this);
      _initializerDefineProperty(this, "cookies", _descriptor4, this);
      _initializerDefineProperty(this, "websockets", _descriptor5, this);
      _initializerDefineProperty(this, "notifications", _descriptor6, this);
      _initializerDefineProperty(this, "intl", _descriptor7, this);
      _defineProperty(this, "socket", null);
      _defineProperty(this, "socketRetries", 0);
      _defineProperty(this, "queryParams", {
        ref: {
          refreshModel: false
        },
        lang: {
          refreshModel: false
        }
      });
      this.notifications.setDefaultClearDuration(3500);
    }
    beforeModel(transition) {
      // Get the browser's language or user preference
      const browserLanguage = navigator.language || navigator.userLanguage;

      // Log the detected language
      console.log('Detected language:', browserLanguage);
      this.intl.setLocale([browserLanguage]);
      this.moment.setLocale(browserLanguage);
      let user = this.session.data.authenticated.user;
      if (transition.to.queryParams.next && user && transition.to.name !== 'login') {
        this.transitionTo(transition.to.queryParams.next);
      }
    }
    async model(params) {
      let cookies = this.cookies;
      if (params.ref) {
        let ref = params.ref.substring(0, 30); // Pas plus de 30 char
        cookies.write('ref', ref, {
          path: "/"
        });
      }
      if (params.lang) {
        const languages = await this.store.findAll("users/language");
        const lang = params.lang || 'en';
        const defaultLanguage = languages.findBy("code", lang) || languages.findBy("code", "en");
        if (defaultLanguage) {
          this.intl.setLocale([defaultLanguage.code, 'en']);
          this.moment.setLocale(defaultLanguage.code);
        }
      }
    }
    afterModel(model, transition) {
      this.connectSocket();
    }
    connectSocket() {
      const socket = this.websockets.socketFor(`${_environment.default.APP.WS_URL}/ws/video`);
      socket.on('open', this.socketOpen, this);
      socket.on('message', this.socketMessage, this);
      socket.on('close', this.socketClose, this);
      this.socket = socket;
    }
    socketOpen(event) {
      console.log("Connected to socket.");
      this.wsLogin();
    }
    socketMessage(event) {
      let data = JSON.parse(event.data);
      if (data.type && data.type === "project_update") {
        let project = this.store.peekRecord('video/project', data.project_id);
        if (project) {
          project.set('renderStatus', data.render_status);
          project.set('getRenderStatusDisplay', data.get_render_status_display);
          project.set('renderProgress', data.render_progress);
          project.set('jobUid', data.job_uid);
          project.set('downloadUrl', data.download_url);
        }
      }
    }
    socketClose(event) {
      console.log("Disconnected from socket. Retrying.");
      let _this = this;
      let socket = this.socket;
      let socketRetries = this.socketRetries;
      setTimeout(() => {
        if (socketRetries > 10) {
          console.log("Websocket is dead. Not retrying.");
        } else {
          socket.reconnect();
          socketRetries++;
          _this.socketRetries = socketRetries;
        }
      }, 2000);
    }
    socketSend(type, data) {
      let socket = this.socket;
      if (socket) {
        socket.send(JSON.stringify({
          "type": type,
          "data": data
        }));
      }
    }
    wsLogin() {
      if (this.session.data.authenticated.access_token) {
        let access_token = this.session.data.authenticated.access_token;
        this.socketSend("authenticate", {
          "token": access_token
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "moment", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cookies", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "websockets", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "wsLogin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "wsLogin"), _class.prototype), _class);
});