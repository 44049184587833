define("clap-front/controllers/account/informations", ["exports", "clap-front/config/environment", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/string"], function (_exports, _environment, _controller, _service, _object, _tracking, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AccountInformationsController = _exports.default = (_class = class AccountInformationsController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "notifications", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "loading", _descriptor4, this);
    }
    async save() {
      this.loading = true;
      try {
        await this.model.save();
        this.loading = false;
        this.notifications.success(this.intl.t('Vos modifications ont bien été enregistrées'));
      } catch (e) {
        console.log('e', e);
        this.loading = false;
        e.errors.forEach(function (error) {
          if (error.source) {
            let pointer = error.source.pointer.split('/').pop(-1);
            this.notifications.error((0, _string.capitalize)(pointer) + ' : ' + error.detail);
          } else {
            this.notifications.clearAll().error(this.intl.t("Erreur pendant l'enregistrement de vos informations"));
          }
        }, this);
      }
    }
    async uploadProfilePicture(file) {
      let userAccessToken = this.session.data.authenticated.access_token;
      let userId = this.session.data.authenticated.user.id;
      this.loading = true;
      let response = await file.upload(`${_environment.default.APP.API_HOST}/api/users/${userId}/upload-picture`, {
        "headers": {
          "Authorization": "Bearer " + userAccessToken
        }
      });
      this.model.set('profile_picture', response.body.profile_picture);
      this.loading = false;
      this.notifications.success(this.intl.t('Votre photo de profil a bien été modifiée'));
      this.session.data.authenticated.user.profile_picture = response.body.profile_picture;
      this.session.store.persist(this.session.data);
    }
    async uploadProfilePicturePro(file) {
      let userAccessToken = this.session.data.authenticated.access_token;
      let userId = this.session.data.authenticated.user.id;
      this.loading = true;
      let response = await file.upload(`${_environment.default.APP.API_HOST}/api/users/${userId}/upload-picture-pro`, {
        "headers": {
          "Authorization": "Bearer " + userAccessToken
        }
      });
      this.model.set('profile_picture_pro', response.body.profile_picture_pro);
      this.loading = false;
      this.notifications.success(this.intl.t('Votre photo de profil professionnelle a bien été modifiée'));
      this.session.data.authenticated.user.profile_picture_pro = response.body.profile_picture_pro;
      this.session.store.persist(this.session.data);
    }
    validatePostalCode(event) {
      const value = event.target.value;
      const codePostal = value.replace(/\D/g, "");
      event.target.value = codePostal;
    }
    validatePhoneNumber(event) {
      const inputElement = event.target;
      const phoneNumberPro = inputElement.value;
      if (phoneNumberPro.startsWith("+")) {
        inputElement.value = "+" + phoneNumberPro.slice(1).replace(/[^0-9]/g, "");
      } else {
        inputElement.value = phoneNumberPro.replace(/[^0-9]/g, "");
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadProfilePicture", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadProfilePicture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadProfilePicturePro", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadProfilePicturePro"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validatePostalCode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validatePostalCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validatePhoneNumber", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validatePhoneNumber"), _class.prototype), _class);
});