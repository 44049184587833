define("clap-front/routes/projects/view", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "rsvp", "clap-front/config/environment"], function (_exports, _route, _service, _object, _rsvp, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProjectsViewRoute = _exports.default = (_class = class ProjectsViewRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "notifications", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
    }
    titleToken(model) {
      return model.project ? `Mes vidéos | vidéo #${model.project.get('id')}` : 'Mes vidéos';
    }
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    }
    async model(params) {
      let project;
      try {
        project = await this.store.findRecord('video/project', params.project_id);
      } catch (err) {
        this.notifications.warning(this.intl.t("Le projet") + ` #${params.project_id}` + " " + this.intl.t("n'existe pas ou vous ne vous appartient pas."));
        return this.router.transitionTo('index');
      }
      if (!project) {
        this.notifications.warning(this.intl.t("Le projet") + ` #${params.project_id}` + " " + this.intl.t("n'existe pas ou vous ne vous appartient pas."));
        return this.router.transitionTo('index');
      }
      let facebookPages = this.modelFor("projects").facebookPages;
      let instagramUser = this.modelFor("projects").instagramUser;
      return _rsvp.default.hash({
        project: project,
        facebookPages: facebookPages,
        instagramUser: instagramUser
      });
    }
    async afterModel(model) {
      if (model.project && model.project.draft) {
        await this.router.transitionTo('index');
      }
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      if (model.project.jobUid) {
        controller.set('watchUrl', _environment.default.APP.FRONT_URL + this.router.urlFor('watch', model.project.jobUid));
      }
    }
    willTransition() {
      this.controller.loading = true;
    }
    didTransition() {
      this.controller.loading = false;
    }
    refreshRouteModel() {
      this.refresh();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshRouteModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRouteModel"), _class.prototype), _class);
});