define("clap-front/templates/connectors/facebook", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "kN6ZXdMM",
    "block": "[[[10,0],[14,0,\"fb-message mx-auto\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\"]],[[[41,[30,0,[\"model\",\"error\",\"message\"]],[[[1,\"            \"],[1,[28,[35,1],[\"Une erreur est survenue\"],null]],[1,\" : \"],[10,\"i\"],[12],[1,[30,0,[\"model\",\"error\",\"message\"]]],[13],[10,\"br\"],[12],[13],[1,\"\\n            \"],[1,[28,[35,1],[\"Merci de réessayer en cliquant\"],null]],[1,\" \"],[8,[39,2],null,[[\"@route\"],[\"connectors\"]],[[\"default\"],[[[[1,[28,[35,1],[\"ici\"],null]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"           \"],[1,[28,[35,1],[\" La liaison avec Facebook a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Facebook.\"],null]],[10,\"br\"],[12],[13],[1,\"\\n            \"],[8,[39,2],[[24,0,\"mt-2 clap-link\"]],[[\"@route\"],[\"connectors\"]],[[\"default\"],[[[[1,[28,[35,1],[\"Retour aux connecteurs\"],null]]],[]]]]],[1,\"\\n\"]],[]]]],[]],null],[13]],[],false,[\"if\",\"t\",\"link-to\"]]",
    "moduleName": "clap-front/templates/connectors/facebook.hbs",
    "isStrictMode": false
  });
});