define("clap-front/controllers/account/settings", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/string"], function (_exports, _controller, _service, _object, _tracking, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AccountSettingsController = _exports.default = (_class = class AccountSettingsController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "notifications", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "loading", _descriptor4, this);
      _initializerDefineProperty(this, "previousColor", _descriptor5, this);
      _initializerDefineProperty(this, "selectedColor", _descriptor6, this);
    }
    async save() {
      this.loading = true;
      this.previousColor = this.model.favorite_color;
      try {
        await this.model.save();
        this.loading = false;
        this.notifications.success(this.intl.t('Les paramètres ont été enregistrés.'));
      } catch (error) {
        this.loading = false;
        error.errors.forEach(function (error) {
          let pointer = error.source.pointer.split('/').pop(-1);
          this.notifications.error((0, _string.capitalize)(pointer) + ' : ' + error.detail);
        }, this);
      }
    }
    selectColor(color) {
      this.selectedColor = color;
    }
    isHexa(color) {
      return /^#[0-9A-F]{6}$/i.test(color);
    }
    onSaveColor() {
      if (this.isHexa(this.selectedColor)) this.model.favorite_color = this.selectedColor;else this.notifications.error(this.intl.t("Couleur incorrecte. Veuillez choisir une couleur au format hexadécimal sans transparence."), {
        clearDuration: 4000
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "previousColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectColor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectColor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSaveColor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSaveColor"), _class.prototype), _class);
});