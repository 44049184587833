define("clap-front/controllers/projects/view", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProjectsViewController = _exports.default = (_class2 = class ProjectsViewController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor5, this);
      _initializerDefineProperty(this, "store", _descriptor6, this);
      _initializerDefineProperty(this, "notifications", _descriptor7, this);
      _initializerDefineProperty(this, "request", _descriptor8, this);
      _initializerDefineProperty(this, "iframeCheck", _descriptor9, this);
      _initializerDefineProperty(this, "intl", _descriptor10, this);
      _initializerDefineProperty(this, "checkboxes", _descriptor11, this);
      _defineProperty(this, "selectedFacebookPageTokenId", '');
      _defineProperty(this, "selectedInstagramTokenId", '');
      _defineProperty(this, "socialVideoTitle", '');
      _defineProperty(this, "socialVideoDescription", '');
      _initializerDefineProperty(this, "socialPostingLoading", _descriptor12, this);
      _initializerDefineProperty(this, "loading", _descriptor13, this);
      _initializerDefineProperty(this, "loadingDuplicate", _descriptor14, this);
      _initializerDefineProperty(this, "dropdownVisible", _descriptor15, this);
      _defineProperty(this, "watchUrl", '');
      _defineProperty(this, "showDeleteConfirmationModal", false);
    }
    get hasSelectedSocialNetwork() {
      return this.checkboxes.isOneSelected();
    }
    get selectedNetworkMessage() {
      return this.checkboxes.getFormattedString();
    }
    copyLink(url) {
      let textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.insertBefore(textArea, document.body.firstChild);
      textArea.focus();
      textArea.select();
      textArea.setSelectionRange(0, 99999); // Mobile selection

      try {
        document.execCommand('copy');
        this.notifications.clearAll().info(this.intl.t('Le lien a été copié'));
        this.dropdownVisible = false;
      } catch (err) {
        this.notifications.clearAll().warning(this.intl.t('Erreur lors de la copie du lien, veuillez réessayer'));
      }
      document.body.removeChild(textArea);
    }
    async duplicateProject() {
      this.loadingDuplicate = true;
      let project = this.model.project;
      let newProject = await project.duplicate({});
      this.transitionToRoute('create.informations', newProject.get('id'));
      this.loadingDuplicate = false;
    }
    postOnSocialMedia(e) {
      e.preventDefault();
      let title = this.socialVideoTitle;
      let description = this.socialVideoDescription;
      if (this.checkboxes.facebook && this.selectedFacebookPageTokenId) {
        this.postOnFacebook(title, description);
      }
      if (this.checkboxes.instagram && this.model.instagramUser.id) {
        this.postOnInstagram(title, description);
      }
    }
    selectFacebookPage(event) {
      console.log('instagram token', this.model.instagramUser.id);
      this.selectedFacebookPageTokenId = event.target.value;
    }
    selectInstagramSwitch(event) {
      //  if facebook is not linked ans instagram active
      // we will disable the drop down of facebook's pages
    }
    async deleteProject() {
      try {
        await this.model.project.destroyRecord();
        this.notifications.success("Projet supprimé.");
        this.send('refreshRouteModel');
      } catch (e) {
        this.notifications.error("Erreur pendant la suppression du projet.");
      }
    }
    get projectJob() {
      if (!this.model.project.isFinished) return null;
      let job = null;
      try {
        job = this.model.project.jobUid ? this.store.find('video/job-watch', this.model.project.jobUid) : null;
      } catch (e) {
        job = null;
      }
      return job;
    }
    async postOnFacebook(title, description, draft = false) {
      let currentProject = this.model.project;
      this.errors = {};
      if (!title.replace(/\s/g, '').length) {
        // Le titre contient seulement des espaces ou tabs etc.
        this.notifications.error(this.intl.t("Veuillez entrer un titre valide"));
        return;
      }
      if (this.selectedFacebookPageTokenId) {
        this.socialPostingLoading = true;
        let selectedFacebookPageTokenId = this.selectedFacebookPageTokenId;
        try {
          await this.request.post(`video/projects/${currentProject.get('id')}/facebook-publish`, {
            name: title,
            description: description,
            draft: draft,
            token: selectedFacebookPageTokenId
          });
          this.notifications.success(this.intl.t("La vidéo est en cours d'envoi sur Facebook. Vous recevrez une notification Facebook dès qu'elle sera prête."));
          this.checkboxes.facebook = false;
          this.socialPostingLoading = false;
        } catch (e) {
          console.log(e);
          let errors = {};
          this.notifications.error(this.intl.t("Erreur lors de l'envoi sur Facebook. Merci de vérifier vos informations et réessayer."));
          this.socialPostingLoading = false;
          e.errors.forEach(function (e) {
            let pointer = e.source.pointer.split('/').pop(-1);
            errors[pointer] = e.detail;
            if (pointer === "non_field_errors") {
              this.notifications.error(e.detail);
            }
          }, this);
          this.errors = errors;
        }
      }
    }
    async postOnInstagram(title, description, draft = false) {
      let currentProject = this.model.project;
      this.errors = {};
      if (!title.replace(/\s/g, '').length) {
        // Le titre contient seulement des espaces ou tabs etc.
        this.notifications.error(this.intl.t("Veuillez entrer un titre valide"));
        return;
      }
      const instagramToken = this.model.instagramUser.id;
      if (instagramToken) {
        this.socialPostingLoading = true;
        let selectedInstagramTokenId = instagramToken;
        try {
          const payload = {
            name: title,
            description: description,
            draft: draft,
            token: selectedInstagramTokenId
          };
          await this.request.post(`video/projects/${currentProject.get('id')}/instagram-publish`, payload);
          this.notifications.success(this.intl.t("La vidéo est en cours d'envoi sur Instagram. Vous recevrez une notification Instagram dès qu'elle sera prête."));
          this.checkboxes.instagram = false;
          this.socialPostingLoading = false;
        } catch (e) {
          console.log(e);
          let errors = {};
          this.notifications.error(this.intl.t("Erreur lors de l'envoi sur Instagram. Merci de vérifier vos informations et réessayer."));
          this.socialPostingLoading = false;
          e.errors.forEach(function (e) {
            let pointer = e.source.pointer.split('/').pop(-1);
            errors[pointer] = e.detail;
            if (pointer === "non_field_errors") {
              this.notifications.error(e.detail);
            }
          }, this);
          this.errors = errors;
        }
      }
    }
  }, _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "request", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "iframeCheck", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "checkboxes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new SocialNetworkCheckboxes();
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "socialPostingLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "loadingDuplicate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "dropdownVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "copyLink", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "copyLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "duplicateProject", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "duplicateProject"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "postOnSocialMedia", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "postOnSocialMedia"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectFacebookPage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectFacebookPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectInstagramSwitch", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectInstagramSwitch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteProject", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteProject"), _class2.prototype), _class2);
  let SocialNetworkCheckboxes = (_class = class SocialNetworkCheckboxes {
    constructor() {
      _initializerDefineProperty(this, "facebook", _descriptor, this);
      _initializerDefineProperty(this, "instagram", _descriptor2, this);
      _initializerDefineProperty(this, "twitter", _descriptor3, this);
      _initializerDefineProperty(this, "youtube", _descriptor4, this);
    }
    isOneSelected() {
      return this.facebook || this.twitter || this.youtube || this.instagram;
    }
    getFormattedString() {
      let message = "";
      let networks = {
        "Facebook": this.facebook,
        "Twitter": this.twitter,
        "Youtube": this.youtube,
        "Instagram": this.instagram
      };
      let selectedNetworks = Object.keys(networks).filter(i => networks[i] !== false);
      selectedNetworks.forEach((network, index) => {
        if (index !== 0) message += index !== selectedNetworks.length - 1 ? ", " : " et ";
        message += network;
      });
      return message;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "facebook", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instagram", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "twitter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "youtube", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class);
});