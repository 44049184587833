define("clap-front/controllers/create/realty-select", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@ember/object/computed", "@glimmer/tracking", "fetch"], function (_exports, _controller, _service, _object, _computed, _tracking, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CreateRealtySelectController = _exports.default = (_dec = (0, _computed.filter)('realties', ['realtySearchValue'], function (realty) {
    if (!this.realtySearchValue) return true;
    let sameReference = realty.reference && realty.reference.includes(this.realtySearchValue);
    let samePrice = realty.price && realty.price.toString().includes(this.realtySearchValue);
    let sameTitle = realty.fr_title && realty.fr_title.toUpperCase().includes(this.realtySearchValue.toUpperCase());
    return sameReference || sameTitle || samePrice;
  }), _class = class CreateRealtySelectController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "request", _descriptor2, this);
      _initializerDefineProperty(this, "notifications", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _defineProperty(this, "realtySearchValue", '');
      _initializerDefineProperty(this, "realties", _descriptor5, this);
      _initializerDefineProperty(this, "loading", _descriptor6, this);
      _initializerDefineProperty(this, "importing", _descriptor7, this);
      // Filter realties by Reference, Title or Price
      _initializerDefineProperty(this, "filteredRealties", _descriptor8, this);
    }
    preventSubmit(e) {
      e.preventDefault();
    }
    async process(platforms) {
      this.loading = true;
      let realties = [];
      platforms.forEach(async platform => {
        let company;
        try {
          company = await platform.get('company');
        } catch (e) {
          e.errors.forEach(error => {
            this.notifications.error(error.detail);
          });
          this.loading = false;
          return;
        }
        let api_url = company.get('jdata.api_url');

        // Get realties from cimm / imooliaison
        let response;
        try {
          response = await (0, _fetch.default)(`${api_url}/realties?agency=${platform.jdata.agency_id}&sold_rented=0`);
        } catch (e) {
          console.log('Une erreur est survenue lors de la récupération des biens : ' + e);
          this.loading = false;
          return;
        }
        if (response.status !== 200) {
          console.log('Une erreur est survenue lors de la récupération des biens : ' + response.status);
          this.notifications.error(this.intl.t('Une erreur est survenue lors de la récupération des biens.'));
          this.loading = false;
          return;
        }
        let data = await response.json();
        realties = realties.concat(data.results);
        this.realties = realties;
        this.loading = false;
      });
    }
    async selectRealty(id) {
      let source = this.model.email.includes("immoliaison") ? "immoliaison" : "cimm";
      this.importing = true;
      let project = this.store.createRecord('video/project');
      await project.save();
      try {
        await project.linkRealty({
          "source": source,
          "realty_id": id
        });
        this.transitionToRoute('create.informations', project.id);
      } catch (e) {
        this.notifications.error(this.intl.t("L'importation de cette annonce n'a pas fonctionné. Si le problème persiste, veuillez contacter le support."));
      }
      this.importing = false;
    }
    async createManual() {
      this.importing = true;
      let project = this.store.createRecord('video/project');
      await project.save();
      this.importing = false;
      this.transitionToRoute('create.informations', project.id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "request", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "realties", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "importing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filteredRealties", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectRealty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectRealty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createManual", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createManual"), _class.prototype), _class);
});