define("clap-front/templates/components/account-sidebar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "be/+3Qkn",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"sticky-container full-height\"],[12],[1,\"\\n  \"],[10,\"nav\"],[14,0,\"account-sidebar\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"account-sidebar-title\"],[14,\"data-test-title\",\"\"],[12],[1,[28,[35,1],[\"Mon compte\"],null]],[13],[1,\"\\n    \"],[8,[39,2],[[24,0,\"account-sidebar-item subtitle\"]],[[\"@route\"],[\"account.informations\"]],[[\"default\"],[[[[1,[28,[35,1],[\"Mes informations\"],null]]],[]]]]],[1,\"\\n    \"],[8,[39,2],[[24,0,\"account-sidebar-item subtitle\"]],[[\"@route\"],[\"account.settings\"]],[[\"default\"],[[[[1,[28,[35,1],[\"Paramètres\"],null]]],[]]]]],[1,\"\\n    \"],[8,[39,2],[[24,0,\"account-sidebar-item subtitle d-flex flex-wrap\"]],[[\"@route\"],[\"account.subscription\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"mr-2\"],[12],[1,[28,[35,1],[\"Abonnement\"],null]],[13],[1,\"\\n\"],[41,[51,[30,0,[\"session\",\"data\",\"authenticated\",\"user\",\"is_subscribed\"]]],[[[1,\"        \"],[10,\"label\"],[14,0,\"upgrade-text\"],[12],[1,[28,[35,1],[\"Passer Premium !\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n    \"],[8,[39,2],[[24,0,\"account-sidebar-item subtitle\"]],[[\"@route\"],[\"account.password\"]],[[\"default\"],[[[[1,[28,[35,1],[\"Mot de passe\"],null]]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"hamburger\",\"t\",\"link-to\",\"unless\"]]",
    "moduleName": "clap-front/templates/components/account-sidebar.hbs",
    "isStrictMode": false
  });
});